<template>
  <div class="container">
    <h2 class="text-center text-blue">Pending Blogs</h2>
    <br /><br />
    <div class="row">
      <div
        v-for="(blog, index) in getUnpublishedBlogs"
        :key="index"
        class="col-md-4 col-sm-6 d-flex align-items-stretch"
      >
        <blog-card :blog="blog" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BlogCard from "./BlogCard.vue";

export default {
  name: "PendingBlogs",
  components: { BlogCard },
  computed: { ...mapGetters(["getUnpublishedBlogs"]) },
};
</script>

    BlogCard